import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_module = _resolveComponent("module")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_routes = _resolveComponent("routes")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Permission" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, { "tab-position": "left" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "Permission in Modules",
            "force-render": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_module)
            ]),
            _: 1
          }),
          _createVNode(_component_a_tab_pane, {
            key: "2",
            tab: "Permission in Routes"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_routes)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}