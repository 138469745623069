import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_form, {
      labelCol: { span: 3 },
      wrapperCol: { span: 6 },
      model: _ctx.form,
      onFinish: _ctx.handleSubmit,
      style: {"margin":"30px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "Select Role" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.role_id,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role_id) = $event)),
              id: "role_id",
              onChange: _ctx.loadRolePermissions,
              required: "",
              "show-search": "",
              filterOption: _ctx.filterOption
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles.data, (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "onChange", "filterOption"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "onFinish"]),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_permissions, (permission) => {
                return (_openBlock(), _createBlock(_component_a_card, {
                  title: permission[0].name,
                  style: {"width":"350px","margin-bottom":"30px","text-align":"left","margin-right":"35px"},
                  key: permission
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(permission, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        style: {"display":"flex","justify-content":"space-between","margin":"10px"}
                      }, [
                        _createElementVNode("span", null, _toDisplayString(item.route), 1),
                        _createVNode(_component_a_switch, {
                          onClick: ($event: any) => (_ctx.updatePermission(item.id)),
                          checked: _ctx.role_permissions.find(x => x.id == item.id) == null ? false : true,
                          disabled: _ctx.selected_role == 'Admin' ? true : false
                        }, null, 8, ["onClick", "checked", "disabled"])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}