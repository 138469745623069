
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Routes from './Routes.vue'
import Module from './Modules.vue'

export default defineComponent({
  components: {
    Routes,
    Module
  },
  setup () {

    return {

      activeKey: ref('1'),
    }
  },
})
