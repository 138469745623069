
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

interface Permission {
  id: string
}

export default defineComponent({
  setup () {
    const store = useStore()
    const role_id = ref('')
    const selected_role = ref('')
    const text = `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`
    const activeKey = ref(['1'])
    const permissions = ref([])
    const role_sub_modules = ref<Permission[]>([])
    const sidebar_modules = ref<Permission[]>([])
    watch(activeKey, (val) => {
      console.log(val)
    })

    const getFormOptions = () => {
      store.dispatch('Option/getAllModules')
      store.dispatch('Option/getAllRoles')
    }

    const loadRolePermissions = () => {
      store.dispatch('Role/getRolePermissions', {id: role_id.value})
          .then(res => {
            role_sub_modules.value = res.data.modules
            sidebar_modules.value = res.data.sidebar_modules
            selected_role.value = res.data.view
          })
    }

    const updatePermission = (payload: any) => {
      let permission = role_sub_modules.value.find(x => x.id == payload.id)
      store.dispatch('Role/updateSubModule', {
        id: payload.id,
        permission: permission,
        role_id: role_id.value,
        module_id: payload.sub_module_id
      })
          .then(() => {
            loadRolePermissions()
          })
    }

    const updateSidebarModule = (payload: string) => {
      let permission = sidebar_modules.value.find(x => x.id == payload)
      store.dispatch('Role/updateSidebarModule', {id: payload, permission: permission, role_id: role_id.value})
          .then(() => {
            loadRolePermissions()
          })
    }

    const all_modules = computed(
        () => store.getters['Option/all_modules']
    )

    const roles = computed(() => store.getters['Option/all_roles'])
    const visible = computed(() => {
      return role_id.value != ''
    })

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    onMounted(getFormOptions)

    return {
      text,
      activeKey,
      all_modules,
      roles,
      loadRolePermissions,
      updatePermission,
      updateSidebarModule,
      visible,
      role_id,
      permissions,
      role_sub_modules,
      sidebar_modules,
      selected_role,
      filterOption
    }
  },
})
