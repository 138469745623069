
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";

interface Permission{
  id: string
}
export default defineComponent({
  setup() {
    const store = useStore();
    const role_id = ref("");
    const selected_role = ref("");
    const text = `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`;
    const activeKey = ref(["1"]);
    const permissions = ref([]);
    const role_permissions = ref<Permission[]>([]);
    watch(activeKey, (val) => {
      console.log(val);
    });

    const getFormOptions = () => {
      store.dispatch("Option/getAllPermissions");
      store.dispatch("Option/getAllRoles");
    };

    const loadRolePermissions = () => {
        store.dispatch("Role/getRolePermissions",{id: role_id.value})
          .then(res => {
            role_permissions.value = res.data.permissions;
            selected_role.value = res.data.view;
          });
    };

    const updatePermission = (payload: string) => {
        let permission = role_permissions.value.find(x => x.id == payload);
        store.dispatch("Role/updatePermission", {id: payload, permission: permission, role_id: role_id.value })
          .then(()=>{
            loadRolePermissions();
          })
    }

    const all_permissions = computed(
      () => store.getters["Option/all_permissions"]
    );

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const roles = computed(() => store.getters["Option/all_roles"]);
    const visible = computed(() => {
        return role_id.value == "" ? false : true;
    });

    onMounted(getFormOptions);

    return {
      text,
      all_permissions,
      roles,
      loadRolePermissions,
      updatePermission,
      visible,
      role_id,
      permissions,
      role_permissions,
      selected_role,
      activeKey: ref('1'),
      filterOption
    };
  },
});
